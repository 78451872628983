<template>
  <div>
    <div class="d-flex mb-5">
      <back-square url="SelectRequestType" />
      <div>
        <h5 class="text-h5 font-weight-bold mb-1">Describe your request</h5>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
        ></v-breadcrumbs>
      </div>
    </div>

    <!-- DEFAULT USER -->
    <v-card 
      v-if="$store.getters['request/canManage']" 
      class="box-shadow" 
      max-width="750"
      outlined
    >
      <v-card-text>
        <v-btn 
          v-if="!status.defaultUser"
          @click="$refs.userDialog.showDialog()" 
          color="primary" 
          plain 
          small
        >
          <v-icon left small>mdi-account-circle-outline</v-icon>
          Search user
        </v-btn>

        <template v-else>
          <v-list-item 
            @click="$refs.userDialog.showDialog()" 
            dense
          >
            <v-list-item-avatar class="overflow-visible" rounded>
              <UserPhoto :size="40" photoSize="thumb" :id="status.defaultUser" rounded />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="secondary--text">
                {{ $store.getters['users/user'](status.defaultUser).fullName }}
              </v-list-item-title>
              <v-list-item-subtitle class="grey--text text--lighten-1">
                {{ $store.getters['users/user'](status.defaultUser).email }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click.prevent="$store.commit('request/updateStatus', { defaultUser: null })" icon>
                <v-icon color="error" small>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          
          <div class="d-flex align-center justify-space-between">
            <v-radio-group label="Priority" v-model="status.priority" row dense>
              <v-radio label="High" value="high"></v-radio>
              <v-radio label="Medium" value="medium"></v-radio>
              <v-radio label="Low" value="low"></v-radio>
            </v-radio-group>
            
            <v-sheet width="100%" max-width="350">
              <v-select 
                v-model="status.defaultProfile"
                :items="company_profiles"
                item-text="name"
                item-value="id"
                hide-details
                outlined
                dense
              ></v-select>
            </v-sheet>
          </div>
        </template>

      </v-card-text>

      <find-user-dialog 
        role="client"
        ref="userDialog" 
        @selected="$store.commit('request/updateStatus', { defaultUser: $event })"
      />
    </v-card>

    <!-- FORM -->
      <v-alert 
        v-if="
          user && 
          user.role === 'client' && 
          profileStatus.firstLoad && 
          !profileStatus.getting && 
          !profiles.length &&
          !companies.length
        " 
        type="warning" 
        border="left" 
        text
      >
        Please add and complete at least one company profile <a href="#" @click.prevent="$router.push({ name: 'BrandProfiles' })">here</a>.
      </v-alert>
      
      <v-card 
        class="mt-5 box-shadow" 
        max-width="750" 
        outlined
        v-else
      >
        <v-card-text v-if="!submitted" class="pa-7">
          <div class="font-weight-bold heading-font secondary--text body-lg">{{ product.name }}</div>

          <v-divider class="my-5"></v-divider>

          <preloader v-if="getting" message="Please wait..." />

          <template v-if="!getting && fields.length">
            <Form 
              ref="form" 
              :user="user" 
              :fields="fields" 
              :profiles="company_profiles" 
              @updateProfile="company_profile = $event" 
            />

            <v-alert v-if="incompleteProfile.length" type="warning" border="left" text>
              Please complete the following details in your company profile: <br/>
              {{ incompleteProfile.join(', ') }}
            </v-alert>

            <div v-if="!getting">
              <v-btn 
                :disabled="!!incompleteProfile.length"
                :loading="status.submitting"
                @click="submitRequest()" 
                color="accent gradient"
                class="mr-2"
              >
                Submit Request
              </v-btn>
              
              <v-btn 
                @click="$refs.form.resetForm()" 
                :disabled="status.submitting"
                depressed
              >
                Clear
              </v-btn>
            </div>
          </template>
        </v-card-text>
        
        <v-card-text class="pa-10" v-if="submitted">
          <v-sheet min-height="350" class="d-flex align-center justify-center">
            <div class="d-flex justify-center flex-column">
              <v-icon color="success" size="120" left>mdi-check-circle-outline</v-icon>
              <span class="title my-5 primary--text">Request Submitted</span>
              <div class="text-center mt-3">
                <v-btn :to="{ name: 'Request', params: { id: newId } }" color="primary">View Request</v-btn>
              </div>
            </div>
          </v-sheet>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
import db from '@/firebase/init'
import { mapState, mapActions } from 'vuex'

import Form from './components/Form'
import FindUserDialog from '../users/components/FindUserDialog.vue'

export default {
  components: {
    Form,
    FindUserDialog,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      fields: [],
      getting: false,
      submitted: false,
      company_profile: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *-----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.request.status,
      profiles: state => state.brand_profile.profiles,
      companies: state => state.brand_profile.companies,
      profileStatus: state => state.brand_profile.status,
    }),

    company_profiles: function () {
      return this.profiles.concat(this.companies)
    },

    incompleteProfile: function () {
      var missing = []

      if (this.company_profile) {
        var profile = this.company_profiles.find(p => p.id === this.company_profile)
        
        if (profile) {
          if (!profile.email) {
            missing.push('Email Address')
          }
          
          if (!profile.phone) {
            missing.push('Phone Number')
          }
          
          if (!profile.locations || (profile.locations && !profile.locations.length)) {
            missing.push('Locations')
          }
        }
      }

      return missing
    },

    product: function () {
      return this.$store.getters['types/getProduct'](this.$route.params.product)
    },
    
    type: function () {
      return this.$store.getters['types/getType'](this.$route.params.id)
    },

    breadcrumbs: function () {
      return [
        {
          to: { name: 'SelectRequestType' },
          text: 'Request Types',
          disabled: false,
          exact: true,
          link: true,
        },
        // {
        //   text: this.$store.getters['types/getType'](this.$route.params.id).name,
        //   to: { name: 'SelectRequestProduct', params: { id: this.$route.params.id } },
        //   disabled: false,
        //   exact: true,
        //   link: true,
        // },
        {
          to: { name: 'RequestForm', params: { id: this.$route.params.id, product: this.$route.params.product } },
          text: this.$store.getters['types/getProduct'](this.$route.params.product).name,
          disabled: false,
          exact: false,
          link: true,
        },
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'status.defaultUser': {
      handler(client) {
        if (client) {
          this.$store.commit('brand_profile/clearProfiles')
          this.getBrandProfiles(client)
          this.getMyCompanies(client)
        }
      },
      immediate: true
    } 
  },


  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('request', [
      'createRequest'
    ]),
    
    ...mapActions('brand_profile', [
      'getBrandProfiles',
      'getMyCompanies'
    ]),
    
    ...mapActions('types', [
      'getProduct',
      'getType',
    ]),

    // GET FIELDS
    getFields() {
      this.getting = true

      db.collection('types')
      .doc(this.$route.params.id)
      .collection('products')
      .doc(this.$route.params.product)
      .collection('fields')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let field = doc.data()
            field.id = doc.id
            field.ref = doc.ref
            this.fields.push(field)
          })
        }

        this.getting = false
      })
      .catch(error => {
        this.getting = false
        console.log(error.message)
      })
    },

    // SUBMIT REQUEST
    submitRequest() {
      if (this.$refs.form.$refs.form.validate()) {
        Promise.resolve(
          this.createRequest({
            fields: this.fields,
            category: this.$route.params.id,
            company_profile: this.company_profile,
            type: this.$store.getters['types/getProduct'](this.$route.params.product),
          })
        )
        .then(
          () => {
            this.$router.push({ name: 'OpenRequests' })
          },
          (error) => {
            this.$store.dispatch('showError', error)
          }
        )
      }
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getFields()
    
    this.getProduct({
      type: this.$route.params.id,
      product: this.$route.params.product
    })

    this.getType(this.$route.params.id)
    this.$store.commit('request/updateStatus', { defaultUser: null })

    if (!this.profileStatus.firstLoad) {
      this.getBrandProfiles()
    }

    if (!this.profileStatus.companiesFirstLoad) {
      this.getMyCompanies()
    }
  }
}
</script>