<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.content"
    scrollable
  >
    <close-btn @click="closeDialog()" overflow />

    <v-card>
      <v-card-title>
        <v-text-field
          @click:clear="$store.commit('users/clearHits')"
          placeholder="Search by name or email"
          class="custom-shadow"
          @input="startSearch"
          v-model="search"
          hide-details
          clearable
          outlined
          rounded
          dense
        >
          <template v-slot:append>
            <v-progress-circular v-if="status.searching" size="25" color="primary" width="2" indeterminate />
            <v-icon color="primary" v-else>mdi-account-search</v-icon>
          </template>
        </v-text-field>
      </v-card-title>
      <v-card-text class="pa-0">
        <preloader v-if="status.searching" />

        <v-fade-transition class="v-list v-sheet theme--light v-list--dense" group>
          <v-list-item
            v-for="user in confHits"
            :key="user.id"
            @click="selectedUser = user.userid"
          >
            <v-list-item-avatar :style="{ overflow: 'visible' }" rounded>
              <UserPhoto :size="40" photoSize="thumb" :id="user.userid" rounded />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title :class="{ 'font-weight-bold primary--text' : isSelected(user.userid) }" >{{ user.fullName }}</v-list-item-title>
              <v-list-item-subtitle class="grey--text text--lighten-1">{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon
               :color="isSelected(user.userid) ? 'success': 'grey lighten-3'" 
              >mdi-checkbox-marked-circle-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-fade-transition>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end gap-3">
        <v-btn
          @click="confirmed()"
          :disabled="!selectedUser"
          color="primary"
          small
        >Confirm</v-btn>
        
        <v-btn
          @click="closeDialog()"
          small
          text
        >Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { debounce } from 'lodash'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    role: {
      type: String,
      default: 'all',
    },
    exclude: String,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      search: null,
      dialog: false,
      selectedUser: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      hits: state => state.users.hits,
      status: state => state.users.status
    }),

    confHits: function () {
      if (this.role === 'all') {
        return this.hits
      }
      else {
        return this.hits.filter(hit => hit.role === this.role)
      }
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    startSearch: debounce(function (value) {
      if (value) this.$store.dispatch('users/searchUser', value)
    }, 1000),

    isSelected(id) {
      this.$store.dispatch('users/getUserUid', id)
      return this.selectedUser == id
    },

    closeDialog() {
      this.dialog = false
      this.selectedUser = null
      this.$store.commit('users/clearHits')
      this.search = null
    },

    showDialog() {
      this.dialog = true
    },

    confirmed() {
      this.$emit('selected', this.selectedUser)
      this.closeDialog()
    }
  }
}
</script>